<template>

</template>

<script>
export default {
    created: function() {
        // console.log(this.$vnode.key);
    }
}

</script>
