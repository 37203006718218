<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :is-full-page="fullPage"
                 :height="300"
                 :width="300"
                 :color="'rgba(91,75,217,0.78)'"
                 :loader="'dots'"
                 :opacity="1"
        ></loading>

        <a :href="'/'" ><img id="exitButton" :src="exitImg" alt="exit"></a>

        <div v-if="errorInRetrieving" class="row" style="margin-top: 9rem;">
            <img id="errorImage" :src="notFoundImage" alt="Data  not found" style="margin-left: -8px; max-width: 100%; margin-bottom: 15px;">
        </div>

        <div v-if="sensorReadings[0]">
        <div class="row-fluid px-3" style="color: #838383">
            <h3 class="my-2">Air Quality Station "<span v-if="node[0]">{{node[0].name}}</span>"</h3>
            <h5 v-if="node[0]">
                {{ node[0].lat.toString().substr(0,2) }}° {{ node[0].lat.toString().substr(3,2)}}' N   {{ node[0].lng.toString().substr(0,2)}}° {{ node[0].lng.toString().substr(3,2)}}' W
                <span v-if="node"> | Latest measurement : <small v-if="sensorReadings[0]">{{ sensorReadings[0]['CREATED_AT'].substr(0, 16) }} </small></span>
            </h5>
        </div>
        <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>

        <div :key="refreshIteration">
            <!-- Gauges -->
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm-4" align="center">
                    <h3>PM2.5</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="pm_2_5"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h
                        min : <b>{{ minMaxReadings.min_pm_2_5 }}</b> |
                        max : <b>{{ minMaxReadings.max_pm_2_5 }}</b>
                    </h5>
                </div>

                <div class="col-sm-4" align="center">
                    <h3>PM10</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="pm_10"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h
                        min : <b>{{ minMaxReadings.min_pm_10 }}</b> |
                        max : <b>{{ minMaxReadings.max_pm_10 }}</b>
                    </h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>CO<sub>2</sub> (ppm)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="co2"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_co2 }}</b> ppm | max : <b>{{ minMaxReadings.max_co2 }}</b> ppm</h5>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h4>Mass concentration PM1.0 (μg/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="mass_concentration_pm1_0"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_mass_concentration_pm1_0 }}</b> μg/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_mass_concentration_pm1_0  }}</b>  μg/m<sup>3</sup></h5>
                </div>
                <div class="col-sm" align="center">
                    <h4>Mass concentration PM2.5 (μg/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="mass_concentration_pm2_5"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_mass_concentration_pm2_5 }}</b> μg/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_mass_concentration_pm2_5  }}</b>  μg/m<sup>3</sup></h5>
                </div>
                <div class="col-sm" align="center">
                    <h4>Mass concentration PM4.0 (μg/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="mass_concentration_pm4_0"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_mass_concentration_pm4_0 }}</b> (μg/m<sup>3</sup>) | max : <b>{{ minMaxReadings.max_mass_concentration_pm4_0 }}</b>  (μg/m<sup>3</sup>)</h5>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">

                <div class="col-sm" align="center">
                    <h4>Mass concentration PM4.0 (μg/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="mass_concentration_pm10_0"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_mass_concentration_pm10_0 }}</b> μg/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_mass_concentration_pm10_0 }}</b>  μg/m<sup>3</sup></h5>
                </div>
                <div class="col-sm" align="center">
                    <h4>Number concentration PM0.5 (counts/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="number_concentration_pm_0_5"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_number_concentration_pm_0_5 }}</b> counts/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_number_concentration_pm_0_5  }}</b>  counts/m<sup>3</sup></h5>
                </div>
                <div class="col-sm" align="center">
                    <h4>Number concentration PM1.0 (counts/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="number_concentration_pm_1_0"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_mass_concentration_pm2_5 }}</b> counts/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_mass_concentration_pm2_5  }}</b>  counts/m<sup>3</sup></h5>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">

                <div class="col-sm" align="center">
                    <h4>Number concentration PM2.5 (counts/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="number_concentration_pm_2_5"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_number_concentration_pm_2_5 }}</b> counts/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_number_concentration_pm_2_5 }}</b>  counts/m<sup>3</sup></h5>
                </div>
                <div class="col-sm" align="center">
                    <h4>Number concentration PM4.0 (counts/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="number_concentration_pm_4_0"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_number_concentration_pm_4_0 }}</b> counts/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_number_concentration_pm_4_0 }}</b>  counts/m<sup>3</sup></h5>
                </div>
                <div class="col-sm" align="center">
                    <h4>Number concentration PM10.0 (counts/m<sup>3</sup>)</h4>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="number_concentration_pm_10_0"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_number_concentration_pm_10_0 }}</b> counts/m<sup>3</sup> | max : <b>{{ minMaxReadings.max_number_concentration_pm_10_0 }}</b>  counts/m<sup>3</sup></h5>
                </div>
            </div>

            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm-4" align="center">
                    <h3>Average particle size (μm)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="typical_particle_size"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h
                        min : <b>{{ minMaxReadings.min_typical_particle_size }}</b> μm |
                        max : <b>{{ minMaxReadings.max_typical_particle_size }}</b> μm
                    </h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>Temperature (°C)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="temp"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_temp }}</b> °C | max : <b>{{ minMaxReadings.max_temp }}</b> °C</h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>Humidity (%)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="hum"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">24 h min : <b>{{ minMaxReadings.min_hum }}</b> % | max : <b>{{ minMaxReadings.max_hum }}</b> %</h5>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>

            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>PM2.5 - 24 hours</h3>
                    <chart v-if="chartReadings[0]" type="pm_2_5" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>PM10.0 - 24 hours</h3>
                    <chart v-if="chartReadings[0]" type="pm_10" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>

            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>CO<sub>2</sub> - 24 hours (ppm)</h3>
                    <chart v-if="chartReadings[0]" type="co2" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>Mass concentration PM1.0 - 24 hours (μg/m<sup>3</sup>)</h3>
                    <chart v-if="chartReadings[0]" type="mass_concentration_pm1_0" :chartReadings="this.chartReadings"></chart>
                </div>

            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>Mass concentration PM2.5 - 24 hours (μg/m<sup>3</sup>)</h3>
                    <chart v-if="chartReadings[0]" type="mass_concentration_pm2_5" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>Mass concentration PM4.0 - 24 hours (μg/m<sup>3</sup>)</h3>
                    <chart v-if="chartReadings[0]" type="mass_concentration_pm4_0" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>Mass concentration PM10.0 - 24 hours (μg/m<sup>3</sup>)</h3>
                    <chart v-if="chartReadings[0]" type="mass_concentration_pm10_0" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>Number concentration PM0.5 - 24 hours (counts/m<sup>3</sup>)</h3>
                    <chart v-if="sensorReadings[0]" type="number_concentration_pm_0_5" :chartReadings="this.sensorReadings"></chart>
                </div>

            </div>

            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5 mb-5">
                <div class="col-sm" align="center">
                    <h3>Number concentration PM1.0 - 24 hours (counts/m<sup>3</sup>)</h3>
                    <chart v-if="chartReadings[0]" type="number_concentration_pm_1_0" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>Number concentration PM2.5 - 24 hours (counts/m<sup>3</sup>)</h3>
                    <chart v-if="chartReadings[0]" type="number_concentration_pm_2_5" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5 mb-5">
                <div class="col-sm" align="center">
                    <h3>Number concentration PM4.0 - 24 hours (counts/m<sup>3</sup>)</h3>
                    <chart v-if="sensorReadings[0]" type="number_concentration_pm_4_0" :chartReadings="this.sensorReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>Number concentration PM10.0 - 24 hours (counts/m<sup>3</sup>)</h3>
                    <chart v-if="chartReadings[0]" type="number_concentration_pm_10_0" :chartReadings="this.chartReadings"></chart>
                </div>

            </div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>Average particle size - 24 hours (μm)</h3>
                    <chart v-if="chartReadings[0]" type="typical_particle_size" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>Temperature - 24 hours (°C)</h3>
                    <chart v-if="chartReadings[0]" type="temperature" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>
            <div class="row mx-5">
                <div class="col-sm-3" align="center"></div>
                <div class="col-sm-6" align="center">
                    <h3>Humidity - 24 hours (%)</h3>
                    <chart v-if="chartReadings[0]" type="humidity" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm-3" align="center"></div>
            </div>
        </div>

        </div>


    </div>
</template>
<script>
import exit from '../../../public/images/close.png'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import notFoundIm from "../../../public/images/satellite.png";

export default {
    props : [ 'imei' ],
    components: {
        Loading
    },
    data() {
        return {
            translations:[],
            sensorReadings: [],
            chartReadings: [],
            minMaxReadings: [],
            node: [],
            exitImg: exit,
            isLoading: false,
            fullPage: true,
            notFoundImage: notFoundIm,
            errorInRetrieving: false,
            timer: "",
            refreshIteration: 0
        }
    },
    created() {
        this.translations = window.translations;
        this.fetchData();
        this.timer = setInterval(this.fetchData, 300000);
    },
    beforeDestroy() {
        this.cancelAutoUpdate();
    },
    methods : {
        fetchData() {
            this.isLoading = true;
            this.axios
                .get('/sensor-data', {
                    params: {
                        imei: this.imei,
                        offset: new Date().getTimezoneOffset()
                    }
                })
                .then(response => {
                    if (response.data === 'error' ) {
                        console.log('err');
                        this.isLoading = false;
                        this.errorInRetrieving = true
                        return;
                    }

                    this.chartReadings = response.data;

                    this.sensorReadings = response.data;

                    this.minMaxReadings['max_temp'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['max_hum'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.humidity}));
                    this.minMaxReadings['max_pm_2_5'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.pm_2_5; }));
                    this.minMaxReadings['max_pm_10'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.pm_10; }));
                    this.minMaxReadings['max_typical_particle_size'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.typical_particle_size; }));
                    this.minMaxReadings['max_co2'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.co2; }));
                    this.minMaxReadings['max_mass_concentration_pm1_0'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm1_0; }));
                    this.minMaxReadings['max_mass_concentration_pm2_5'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm2_5; }));
                    this.minMaxReadings['max_mass_concentration_pm4_0'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm4_0; }));
                    this.minMaxReadings['max_mass_concentration_pm10_0'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm10_0; }));
                    this.minMaxReadings['max_number_concentration_pm_0_5'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_0_5; }));
                    this.minMaxReadings['max_number_concentration_pm_1_0'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_1_0; }));
                    this.minMaxReadings['max_number_concentration_pm_2_5'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_2_5; }));
                    this.minMaxReadings['max_number_concentration_pm_4_0'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_4_0; }));
                    this.minMaxReadings['max_number_concentration_pm_10_0'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_10_0; }));

                    this.minMaxReadings['min_temp'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['min_hum'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.humidity; }));
                    this.minMaxReadings['min_pm_2_5'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.pm_2_5; }));
                    this.minMaxReadings['min_pm_10'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.pm_10; }));
                    this.minMaxReadings['min_typical_particle_size'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.typical_particle_size; }));
                    this.minMaxReadings['min_co2'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.co2; }));
                    this.minMaxReadings['min_mass_concentration_pm1_0'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm1_0; }));
                    this.minMaxReadings['min_mass_concentration_pm2_5'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm2_5; }));
                    this.minMaxReadings['min_mass_concentration_pm4_0'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm4_0; }));
                    this.minMaxReadings['min_mass_concentration_pm10_0'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.mass_concentration_pm10_0; }));
                    this.minMaxReadings['min_number_concentration_pm_0_5'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_0_5; }));
                    this.minMaxReadings['min_number_concentration_pm_1_0'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_1_0; }));
                    this.minMaxReadings['min_number_concentration_pm_2_5'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_2_5; }));
                    this.minMaxReadings['min_number_concentration_pm_4_0'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_4_0; }));
                    this.minMaxReadings['min_number_concentration_pm_10_0'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.number_concentration_pm_10_0; }));

                    this.refreshIteration += 1;

                    return this.axios
                        .get('/node-basic-info', {
                        params: {
                            imei: this.imei
                        }
                    })
                })
                .then(response => {
                    this.node = response.data
                    // console.log(this.node);
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                })
        },
        cancelAutoUpdate() {
            clearInterval(this.timer);
        },
    }
}
</script>

<style scoped >
#exitButton {
    position: fixed;
    z-index: 100;
    width: 30px;
    right: 50px;
    filter: opacity(60%);
}
#errorImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -15%);
    margin-top: 1rem;
}
@media all and (max-width: 800px) {
    .row {
        margin-left : 0.5rem !important;
        margin-right : 0.5rem !important;
    }
    .minMaxText {
        margin-bottom: 45px !important;
    }
    #exitButton {
        position: fixed;
        z-index: 100;
        width: 30px;
        right: 25px;
        filter: opacity(70%);
    }
}

</style>
