<template>
    <div class="chart" ref="chartdiv">
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

export default {
    name: 'Chart',
    props: ['chartReadings', 'type'],
    data() {
        return {}
    },
    methods: {
        createChart() {
            let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
            chart.paddingRight = 20;

            let data = [];
            this.chartReadings.forEach(sensorData => {
                data.push({
                    date: new Date(sensorData.CREATED_AT),
                    name: 'name' + Math.random(),
                    value: sensorData[this.type]
                })
            });

            chart.data = data;

            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.minGridDistance = 75;
            dateAxis.renderer.grid.template.location = 0;
            dateAxis.baseInterval = {
                "timeUnit": "minute",
                "count": 15
            }

// this hides regular labels close to the start/end
            dateAxis.renderer.minLabelPosition = 0.15;
            dateAxis.renderer.maxLabelPosition = 0.85;

// add ranges
            var minRange = dateAxis.axisRanges.create();
// this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
            minRange.maxPosition = 1;
            minRange.minPosition = 0;
            minRange.label.horizontalCenter = "left"
            minRange.label.paddingLeft = 0;


            var maxRange = dateAxis.axisRanges.create();
// this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
            maxRange.maxPosition = 1;
            maxRange.minPosition = 0;
            maxRange.label.horizontalCenter = "right"
            maxRange.label.paddingRight = 0;

            dateAxis.events.on("startendchanged", updateRangeLabels)
            dateAxis.events.on("extremeschanged", updateRangeLabels)

            function updateRangeLabels() {
                minRange.value = dateAxis.min + dateAxis.start * (dateAxis.max - dateAxis.min);
                minRange.label.text = dateAxis.dateFormatter.format(minRange.value, "HH:mm");

                maxRange.value = dateAxis.min + dateAxis.end * (dateAxis.max - dateAxis.min);
                maxRange.label.text = dateAxis.dateFormatter.format(maxRange.value, "HH:mm");
            }

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.tooltip.disabled = true;
            valueAxis.renderer.minWidth = 35;

            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = "date";
            series.dataFields.valueY = "value";

            if (this.type === 'temperature') {

                var gradient = new am4core.LinearGradient();
                gradient.addColor(am4core.color("blue"));
                gradient.addColor(am4core.color("yellow"));
                gradient.addColor(am4core.color("orange"));
                gradient.addColor(am4core.color("red"));
                gradient.rotation = 270;
                series.fill = gradient;
                series.fillOpacity = 0.75;

            } else if (this.type === 'pm_2_5') {
                let line_weight = 3;
                let data = [];
                let max_value = 0;
                this.chartReadings.forEach(sensorData => {
                    if(max_value <= sensorData[this.type]){
                        max_value = sensorData[this.type];
                    }
                    data.push({
                        date: new Date(sensorData.CREATED_AT),
                        name: 'name' + Math.random(),
                        value: sensorData[this.type],
                        green_line_value: 50,
                        yellow_line_value: 100,
                        orange_line_value: 150,
                        red_line_value: 200,
                        purple_line_value: 300,
                        maroon_line_value: 500,
                    })
                });

                chart.data = data;
                if(max_value >= 0 ){
                    let green_line = chart.series.push(new am4charts.LineSeries());
                    green_line.dataFields.dateX = "date";
                    green_line.dataFields.valueY = "green_line_value";
                    green_line.strokeWidth = line_weight;
                    green_line.stroke = am4core.color("#7CFC00");
                    green_line.strokeDasharray  = "4 4";
                }

                if(max_value >= 50){
                    let yellow_line = chart.series.push(new am4charts.LineSeries());
                    yellow_line.dataFields.dateX = "date";
                    yellow_line.dataFields.valueY = "yellow_line_value";
                    yellow_line.strokeWidth = line_weight;
                    yellow_line.stroke = am4core.color("#e6e600");
                    yellow_line.strokeDasharray  = "4 4";

                }
                if(max_value >= 100) {
                    let orange_line = chart.series.push(new am4charts.LineSeries());
                    orange_line.dataFields.dateX = "date";
                    orange_line.dataFields.valueY = "orange_line_value";
                    orange_line.strokeWidth = line_weight;
                    orange_line.stroke = am4core.color("#FFA500");
                    orange_line.strokeDasharray  = "4 4";

                }

                if(max_value >= 150){
                    let red_line = chart.series.push(new am4charts.LineSeries());
                    red_line.dataFields.dateX = "date";
                    red_line.dataFields.valueY = "red_line_value";
                    red_line.strokeWidth = line_weight;
                    red_line.stroke = am4core.color("#FF0000");
                    red_line.strokeDasharray  = "4 4";

                }

                if(max_value >= 200){
                    let purple_line = chart.series.push(new am4charts.LineSeries());
                    purple_line.dataFields.dateX = "date";
                    purple_line.dataFields.valueY = "purple_line_value";
                    purple_line.strokeWidth = line_weight;
                    purple_line.stroke = am4core.color("#FF0000");
                    purple_line.strokeDasharray  = "4 4";
                }

                if(max_value >= 300){
                    let maroon_line = chart.series.push(new am4charts.LineSeries());
                    maroon_line.dataFields.dateX = "date";
                    maroon_line.dataFields.valueY = "maroon_line_value";
                    maroon_line.strokeWidth = line_weight;
                    maroon_line.stroke = am4core.color("#800000");
                    maroon_line.strokeDasharray  = "4 4";
                }

                series.fill = am4core.color("#81bdbf"); // fill
                series.fillOpacity = 1;

                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0, 1];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;

            } else if (this.type === 'pm_10') {
                let line_weight = 3;
                let data = [];
                let max_value = 0;
                this.chartReadings.forEach(sensorData => {
                    if(max_value <= sensorData[this.type]){
                        max_value = sensorData[this.type];
                    }
                    data.push({
                        date: new Date(sensorData.CREATED_AT),
                        name: 'name' + Math.random(),
                        value: sensorData[this.type],
                        green_line_value: 50,
                        yellow_line_value: 100,
                        orange_line_value: 150,
                        red_line_value: 200,
                        purple_line_value: 300,
                        maroon_line_value: 500,
                    })
                });

                chart.data = data;
                if(max_value >= 0 ){
                    let green_line = chart.series.push(new am4charts.LineSeries());
                    green_line.dataFields.dateX = "date";
                    green_line.dataFields.valueY = "green_line_value";
                    green_line.strokeWidth = line_weight;
                    green_line.stroke = am4core.color("#7CFC00");
                    green_line.strokeDasharray  = "4 4";
                }

                if(max_value >= 50){
                    let yellow_line = chart.series.push(new am4charts.LineSeries());
                    yellow_line.dataFields.dateX = "date";
                    yellow_line.dataFields.valueY = "yellow_line_value";
                    yellow_line.strokeWidth = line_weight;
                    yellow_line.stroke = am4core.color("#e6e600");
                    yellow_line.strokeDasharray  = "4 4";

                }
                if(max_value >= 100) {
                    let orange_line = chart.series.push(new am4charts.LineSeries());
                    orange_line.dataFields.dateX = "date";
                    orange_line.dataFields.valueY = "orange_line_value";
                    orange_line.strokeWidth = line_weight;
                    orange_line.stroke = am4core.color("#FFA500");
                    orange_line.strokeDasharray  = "4 4";

                }

                if(max_value >= 150){
                    let red_line = chart.series.push(new am4charts.LineSeries());
                    red_line.dataFields.dateX = "date";
                    red_line.dataFields.valueY = "red_line_value";
                    red_line.strokeWidth = line_weight;
                    red_line.stroke = am4core.color("#FF0000");
                    red_line.strokeDasharray  = "4 4";

                }

                if(max_value >= 200){
                    let purple_line = chart.series.push(new am4charts.LineSeries());
                    purple_line.dataFields.dateX = "date";
                    purple_line.dataFields.valueY = "purple_line_value";
                    purple_line.strokeWidth = line_weight;
                    purple_line.stroke = am4core.color("#FF0000");
                    purple_line.strokeDasharray  = "4 4";
                }

                if(max_value >= 300){
                    let maroon_line = chart.series.push(new am4charts.LineSeries());
                    maroon_line.dataFields.dateX = "date";
                    maroon_line.dataFields.valueY = "maroon_line_value";
                    maroon_line.strokeWidth = line_weight;
                    maroon_line.stroke = am4core.color("#800000");
                    maroon_line.strokeDasharray  = "4 4";
                }

                series.fill = am4core.color("#81bdbf"); // fill
                series.fillOpacity = 1;
                // valueAxis.min = 1000;
                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0,  1];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;
            } else {
                valueAxis.min = 0;
                series.fill = am4core.color("#81bdbf"); // fill
                series.fillOpacity = 1;

                var fillModifier = new am4core.LinearGradientModifier();
                fillModifier.opacities = [1, 0];
                fillModifier.offsets = [0, 1100];
                fillModifier.gradient.rotation = 90;
                series.segments.template.fillModifier = fillModifier;
            }


            let bullet = series.bullets.push(new am4charts.Bullet());
            let square = bullet.createChild(am4core.Rectangle);
            square.width = 3;
            square.height = 3;

            series.tooltipText = "{valueY.value}";
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color("white");
            series.tooltip.label.fill = am4core.color('#05969a');
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "none";

            this.chart = chart;
        }
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
    mounted() {
        this.createChart()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
    margin-top: 10px;
}
@media all and (max-width: 800px) {
    .chart {
        width: 100%;
        height: 250px;
        margin-top: 10px;
    }
}
</style>
