<template>
    <div class="hello" ref="chartdiv">
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
    name: 'Gauge',
    props: [ 'sensorReadings', 'type' ],
    data() {
        return {}
    },
    methods: {
        createChart() {
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create(this.$refs.chartdiv, am4charts.GaugeChart);
            let axis = chart.xAxes.push(new am4charts.ValueAxis());


            axis.strictMinMax = true;
            chart.innerRadius = -20;
            // Add ranges
            let range = axis.axisRanges.create();
            range.axisFill.fillOpacity = 1;
            range.axisFill.zIndex = - 1;

            let range2 = axis.axisRanges.create();
            range2.axisFill.fillOpacity = 1;
            range2.axisFill.zIndex = - 1;

            let range3 = axis.axisRanges.create();
            range3.axisFill.fillOpacity = 1;
            range3.axisFill.zIndex = - 1;

            let range4 = axis.axisRanges.create();
            range4.axisFill.fillOpacity = 1;
            range4.axisFill.zIndex = - 1;

            let range5 = axis.axisRanges.create();
            range5.axisFill.fillOpacity = 1;
            range5.axisFill.zIndex = - 1;

            let range6 = axis.axisRanges.create();
            range6.axisFill.fillOpacity = 1;
            range6.axisFill.zIndex = - 1;

            // Add hand
            let hand = chart.hands.push(new am4charts.ClockHand());
            hand.pin.disabled =  true;
            hand.fill = am4core.color("#2D93AD");
            hand.stroke = am4core.color("#2D93AD");
            hand.innerRadius = am4core.percent(50);
            hand.radius = am4core.percent(80);
            hand.startWidth = 15;

            var label = chart.radarContainer.createChild(am4core.Label);
            label.isMeasured = false;
            label.fontSize = 25;
            label.x = am4core.percent(50);
            label.y = am4core.percent(80);
            label.horizontalCenter = "middle";
            label.verticalCenter = "bottom";

            if (this.type === 'temp') {
                axis.min = -20;
                axis.max = 50;
                range.axisFill.fill = am4core.color("#4c59ac");
                range.value = -20;
                range.endValue = 0;
                range2.axisFill.fill = am4core.color("rgba(32,184,32,0.84)");
                range2.value = 0;
                range2.endValue = 25;
                range3.axisFill.fill = am4core.color("rgba(231,125,53,0.93)");
                range3.value = 25;
                range3.endValue = 40;
                range4.axisFill.fill = am4core.color("rgba(234,39,39,0.95)");
                range4.value = 40;
                range4.endValue = 50;

                hand.value = this.sensorReadings[0].temperature;
                label.text = this.sensorReadings[0].temperature + '°C';
            } else if (this.type === 'hum') {
                axis.min = 0;
                axis.max = 100;    range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 20;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 20;
                range2.endValue = 40;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 40;
                range3.endValue = 70;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 70;
                range4.endValue = 100;

                hand.value = this.sensorReadings[0].humidity;
                label.text = this.sensorReadings[0].humidity + '%';
            } else if (this.type === 'pm_2_5') {
                axis.min = 0;
                axis.max = 500;    range.axisFill.fill = am4core.color("rgba(0,255,0,1)");
                range.value = 0;
                range.endValue = 50;
                range2.axisFill.fill = am4core.color("rgba(255,255,0,1)");
                range2.value = 50;
                range2.endValue = 100;
                range3.axisFill.fill = am4core.color("rgba(255,165,0,1)");
                range3.value = 100;
                range3.endValue = 150;
                range4.axisFill.fill = am4core.color("rgb(255,0,0)");
                range4.value = 150;
                range4.endValue = 200;
                range5.axisFill.fill = am4core.color("rgb(128,0,128)");
                range5.value = 200;
                range5.endValue = 300;
                range6.axisFill.fill = am4core.color("rgb(128,0,0)");
                range6.value = 300;
                range6.endValue = 500;


                hand.value = this.sensorReadings[0].pm_2_5;
                label.text = this.sensorReadings[0].pm_2_5;
            }else if (this.type === 'pm_10') {
                axis.min = 0;
                axis.max = 500;    range.axisFill.fill = am4core.color("rgba(0,255,0,1)");
                range.value = 0;
                range.endValue = 50;
                range2.axisFill.fill = am4core.color("rgba(255,255,0,1)");
                range2.value = 51;
                range2.endValue = 100;
                range3.axisFill.fill = am4core.color("rgba(255,165,0,1)");
                range3.value = 101;
                range3.endValue = 150;
                range4.axisFill.fill = am4core.color("rgb(255,0,0)");
                range4.value = 151;
                range4.endValue = 200;
                range5.axisFill.fill = am4core.color("rgb(128,0,128)");
                range5.value = 201;
                range5.endValue = 300;
                range6.axisFill.fill = am4core.color("rgb(128,0,0)");
                range6.value = 301;
                range6.endValue = 500;


                hand.value = this.sensorReadings[0].pm_10;
                label.text = this.sensorReadings[0].pm_10;
            } else if (this.type === 'mass_concentration_pm1_0' ||
                this.type === 'mass_concentration_pm2_5' ||
                this.type === 'mass_concentration_pm4_0' ||
                this.type === 'mass_concentration_pm10_0') {

                axis.min = 0;
                axis.max = 1000;
                range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 250;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 250;
                range2.endValue = 500;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 500;
                range3.endValue = 750;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 750;
                range4.endValue = 1000;

                hand.value = this.sensorReadings[0][this.type];
                label.text = this.sensorReadings[0][this.type] + ' μg/m³\n';
            }
            else if (this.type === 'number_concentration_pm_0_5' ||
                this.type === 'number_concentration_pm_1_0' ||
                this.type === 'number_concentration_pm_2_5' ||
                this.type === 'number_concentration_pm_4_0' ||
                this.type === 'number_concentration_pm_10_0') {

                axis.min = 0;
                axis.max = 3000;
                range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 500;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 500;
                range2.endValue = 1000;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 1000;
                range3.endValue = 2000;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 2000;
                range4.endValue = 3000;

                hand.value = this.sensorReadings[0][this.type];
                label.text = this.sensorReadings[0][this.type] + ' counts/m³\n';
            }
            else if (this.type === 'typical_particle_size') {
                axis.min = 0;
                axis.max = 10;
                range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 2.5;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range2.value = 2.5;
                range2.endValue = 5;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.76)");
                range3.value = 5;
                range3.endValue = 7.5;
                range4.axisFill.fill = am4core.color("rgb(76,89,172)");
                range4.value = 7.5;
                range4.endValue = 10;

                hand.value = this.sensorReadings[0].typical_particle_size;
                label.html = this.sensorReadings[0].typical_particle_size + ' μm';
            }
            else if (this.type === 'co2') {

                axis.min = 0;
                axis.max = 40000;
                axis.renderer.minGridDistance = 10000;
                range.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range.value = 0;
                range.endValue = 1000;
                range.label.text = ""+axis.min;
                range2.axisFill.fill = am4core.color("rgba(76,89,172,0.24)");
                range2.value = 1000;
                range2.endValue = 5000;
                range3.axisFill.fill = am4core.color("rgba(76,89,172,0.46)");
                range3.value = 5000;
                range3.endValue = 10000;
                range3.label.text = "5000";
                range4.axisFill.fill = am4core.color("rgba(76,89,172,0.60)");
                range4.value = 10000;
                range4.endValue = 30000;
                range4.label.text = "10000";
                range5.axisFill.fill = am4core.color("rgb(76,89,172)");
                range5.value = 30000;
                range5.label.text = "30000";
                range5.endValue = 40000;
                range6.axisFill.fill = am4core.color("rgb(76,89,172)");
                range6.value = 40000;
                range6.label.text = "40000";
                range6.endValue = 40000;

                hand.value = this.sensorReadings[0].co2;
                label.html = this.sensorReadings[0].co2 + ' ppm';
            }

        },
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
        }
    },
    mounted() {
        this.createChart()
    },
}
</script>

<style scoped>
.hello {
    width: 80%;
    height: 300px;
    margin-top: 10px;
}
@media all and (max-width: 800px) {
    .hello {
        width: 100%;
        height: 200px;
        margin-top: 10px;
    }

}
</style>
