<template>
    <div class="container justify-content-start">
        <!-- Modal -->
        <div class="modal left" :id="'sidebar_'+ node.imei" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">

                <div class="modal-content">
                    <loading :active.sync="isLoading"
                             :is-full-page="fullPage"
                             :height="300"
                             :width="300"
                             :color="'rgba(91,75,217,0.78)'"
                             :loader="'dots'"
                    ></loading>

                    <div class="modal-body">
                        <div class="row" v-if="sensorReadings[0]">
                            <img class="exitButton" :src="exitImg" alt="exit" @click="closeModal">
                        </div>
<!--                        <div class="row" v-if="sensorReadings[0]">-->
<!--                            <div class="sidebar-img" :style="{ 'background-image': 'url(' + sensorImg + ')'}">-->
<!--                                <img class="exitButton" :src="exitImg" alt="exit" @click="closeModal">-->
<!--                            </div>-->
<!--                        </div>-->

                        <div v-if="errorInRetrieving" class="row" style="margin-top: 9rem;">
                            <img class="exitButton" :src="exitImg" alt="exit" @click="closeModal">
                            <img :src="notFoundImage" alt="Data  not found" style="margin-left: -8px; max-width: 100%; max-height: auto;">
                            <a class="btn btn-primary btn-large btn-block mx-4" style="margin-top:10px" @click="closeModal"><b>Επιστροφή</b></a>
                        </div>


                        <div v-if="sensorReadings[0]" id="sidebarBlueBox" class="row text-center pt-3" style="background-color: cornflowerblue; height: auto; color: white">
                            <div class="container row pb-3">
                                <div class="col"><h6>{{ node.lat.toString().substr(0,2) }}° {{ node.lat.toString().substr(3,2)}}' N   {{ node.lng.toString().substr(0,2)}}° {{ node.lng.toString().substr(3,2)}}' W</h6></div>
                                <div class="col"><h5> {{ node.name || '-'}} </h5></div>
                                <div class="col">
                                    <h6 v-if="sensorReadings[0]" class="mr-2" style="position: absolute; right: 0;">
                                    Latest measurement: {{ sensorReadings[0]['CREATED_AT'].substr(0, 16) }}
                                    </h6>
                                </div>
                            </div>
                            <div class="container row">
                                <div class="col">
                                    <h1 class="minMaxText" style="margin-top: 10px">{{ sensorReadings[0]['pm_2_5'] }}
                                        <small></small></h1>
                                    <h6>PM2.5</h6>
                                    <h6 v-if="minMaxReadings">24 h min: <b>{{ minMaxReadings.min_pm_2_5 }}</b> |
                                        max: <b>{{ minMaxReadings.max_pm_2_5 }}</b>  </h6>
                                </div>
                                <div class="col">
                                    <h1 class="minMaxText" style="margin-top: 10px">
                                        {{ sensorReadings[0]['pm_10'] }}</h1>
                                    <h6>PM10</h6>
                                    <h6 v-if="minMaxReadings">24 h min: <b>{{ minMaxReadings.min_pm_10 }}</b> |
                                        max: <b>{{ minMaxReadings.max_pm_10 }}</b>  </h6>
                                </div>
                            </div>

                        </div>
                        <div class="row" v-if="sensorReadings[0]">
                            <h4 class="m-2"> PM2.5 (Last 24 hours) </h4>
                            <chart v-if="chartReadings[0]" type="pm_2_5" :chartReadings="this.chartReadings"></chart>
                        </div>
                        <div class="row" v-if="sensorReadings[0]">
                            <h4 class="m-2"> PM10 (Last 24 hours) </h4>
                            <chart v-if="chartReadings[0]" type="pm_10" :chartReadings="this.chartReadings"></chart>
                        </div>
                        <div class="row" v-if="sensorReadings[0]">
                            <h4 class="m-2"> CO<sub>2</sub> (Last 24 hours) </h4>
                            <chart v-if="chartReadings[0]" type="co2" :chartReadings="this.chartReadings"></chart>
                        </div>
                        <div class="row" style="margin-left:21px; margin-right: 35px;">
                            <ul v-if="sensorReadings[0]" class="list-group list-group-flush" style="width: 100%;">
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-fog" viewBox="0 0 16 16">
                                        <path d="M3 13.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm10.405-9.473a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 12H13a3 3 0 0 0 .405-5.973zM8.5 3a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 3z"/>
                                    </svg>
                                    PM2.5
                                    <span class="float-right">
                                            {{ sensorReadings[0]['pm_2_5'] }}
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-fog" viewBox="0 0 16 16">
                                        <path d="M3 13.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm10.405-9.473a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 12H13a3 3 0 0 0 .405-5.973zM8.5 3a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 3z"/>
                                    </svg>
                                    PM10
                                    <span class="float-right">
                                        {{ sensorReadings[0]['pm_10'] }}
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-haze" viewBox="0 0 16 16">
                                        <path d="M4 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm2 2a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM13.405 4.027a5.001 5.001 0 0 0-9.499-1.004A3.5 3.5 0 1 0 3.5 10H13a3 3 0 0 0 .405-5.973zM8.5 1a4 4 0 0 1 3.976 3.555.5.5 0 0 0 .5.445H13a2 2 0 0 1 0 4H3.5a2.5 2.5 0 1 1 .605-4.926.5.5 0 0 0 .596-.329A4.002 4.002 0 0 1 8.5 1z"/>
                                    </svg>
                                    CO<sub>2</sub>
                                    <span class="float-right">
                                        {{ sensorReadings[0]['co2'] }} <small>ppm</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wind" viewBox="0 0 16 16">
                                        <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    Mass concentration PM1.0
                                    <span class="float-right">
                                        {{ sensorReadings[0]['mass_concentration_pm1_0'] }} <small>μg/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wind" viewBox="0 0 16 16">
                                        <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    Mass concentration PM2.5
                                    <span class="float-right">
                                        {{ sensorReadings[0]['mass_concentration_pm2_5'] }} <small>μg/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wind" viewBox="0 0 16 16">
                                        <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    Mass concentration PM4.0
                                    <span class="float-right">
                                        {{ sensorReadings[0]['mass_concentration_pm4_0'] }} <small>μg/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wind" viewBox="0 0 16 16">
                                        <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    Mass concentration PM10.0
                                    <span class="float-right">
                                        {{ sensorReadings[0]['mass_concentration_pm10_0'] }} <small>μg/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                                    </svg>
                                    Number concentration PM0.5
                                    <span class="float-right">
                                        {{ sensorReadings[0]['number_concentration_pm_0_5'] }} <small>counts/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                                    </svg>
                                    Number concentration PM1.0
                                    <span class="float-right">
                                        {{ sensorReadings[0]['number_concentration_pm_1_0'] }} <small>counts/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                                    </svg>
                                    Number concentration PM2.5
                                    <span class="float-right">
                                        {{ sensorReadings[0]['number_concentration_pm_2_5'] }} <small>counts/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                                    </svg>
                                    Number concentration PM4.0
                                    <span class="float-right">
                                        {{ sensorReadings[0]['number_concentration_pm_4_0'] }} <small>counts/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                                    </svg>
                                    Number concentration PM10.0
                                    <span class="float-right">
                                        {{ sensorReadings[0]['number_concentration_pm_10_0'] }} <small>counts/m<sup>3</sup></small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
                                    </svg>
                                    Average particle size
                                    <span class="float-right">
                                        {{ sensorReadings[0]['typical_particle_size'] }} <small>μm</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-thermometer-half" viewBox="0 0 16 16">
                                        <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z"/>
                                        <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z"/>
                                    </svg>
                                    Temperature
                                    <span class="float-right">
                                        {{ sensorReadings[0]['temperature'] }} <small>°C</small>
                                    </span>
                                </li>
                                <li class="list-group-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-droplet-half" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
                                        <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"/>
                                    </svg>
                                    Humidity
                                    <span class="float-right">
                                        {{ sensorReadings[0]['humidity'] }} <small>%</small>
                                    </span>
                                </li>

                            </ul>
                        </div>

                        <a v-if="sensorReadings[0]" class="btn btn-primary btn-large btn-block" :href="'/details/' + node.name" style="margin-top:10px"><b>Details</b></a>
                    </div>
                    </div>
                </div><!-- modal-content -->
            </div><!-- modal-dialog -->
        </div><!-- modal -->
</template>

<script>
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import sensorPlaceholderImage from '../../../public/images/sensorsImgPlaceholder.png'
import sensorPlaceholderImage1 from '../../../public/images/sensorsImgPlaceholder1.png'
import sensorPlaceholderImage2 from '../../../public/images/sensonsImgPlaceholder2.png'
import sensorPlaceholderImage3 from '../../../public/images/sensonsImgPlaceholder3.png'
import exit from "../../../public/images/close.png";
import notFoundIm from "../../../public/images/satellite.png"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

let images = [sensorPlaceholderImage, sensorPlaceholderImage1, sensorPlaceholderImage2, sensorPlaceholderImage3];

export default {
    props: ['node'],
    components: {
        Loading
    },
    data() {
        return {
            sensorReadings: [],
            chartReadings: [],
            minMaxReadings: [],
            beaufort: 0,
            gustBeaufort: 0,
            windDirection: '',
            uv: 0,
            sensorImg: images[Math.floor(Math.random() * images.length)],
            exitImg: exit,
            notFoundImage: notFoundIm,
            isLoading: false,
            fullPage: false,
            errorInRetrieving: false

        }
    },
    methods : {
        loadNodeData() {
            this.isLoading = true;

            this.axios
                .get('/sensor-data', {
                    params: {
                        imei: this.node.imei,
                        offset: new Date().getTimezoneOffset()
                    }
                })
                .then(response => {
                    if (response.data === 'error' ) {
                        this.isLoading = false;
                        this.errorInRetrieving = true
                        return;
                    }

                    this.chartReadings = response.data;
                    this.sensorReadings = response.data;

                    this.minMaxReadings['max_pm_2_5'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.pm_2_5; }));
                    this.minMaxReadings['min_pm_2_5'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.pm_2_5; }));
                    this.minMaxReadings['max_pm_10'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.pm_10; }));
                    this.minMaxReadings['min_pm_10'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.pm_10; }));
                    this.minMaxReadings['max_temp'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['min_temp'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['max_humidity'] = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.humidity; }));
                    this.minMaxReadings['min_humidity'] = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.humidity; }));
                    this.isLoading = false;


                })
        },
        closeModal() {
            $("#sidebar_" + this.node.imei).modal("toggle");
        }
    }
}
</script>

<style type="text/css" scoped>
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    width: 180%;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 0px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog{
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
    left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
    right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    display: block;
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
}

demo {
    padding-top: 60px;
    padding-bottom: 110px;
}

.btn-demo {
    margin: 15px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 16px;
    background-color: #FFFFFF;
}

.btn-demo:focus {
    outline: 0;
}

.demo-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    background-color: #212121;
    text-align: center;
}

.demo-footer > a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
}
.sidebar-img {
    width: 100%;
    height:0vh;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    display: flex;
}
.sidebar-img span {
    align-self: flex-end;
}
.exitButton {
    display:none;
}
.minMaxText {
    font-size:3.25rem;
}

@media all and (max-width: 800px) {
    .modal.left .modal-content,
    .modal.right .modal-content {
        width: 100%;
    }
    .modal-dialog {
        width: auto !important;
    }
    .exitButton {
        width: 18px;
        position: fixed;
        right: 22px;
        top: 19px;
        display: block;
        cursor: pointer;
        z-index: 1000;
    }
    .minMaxText {
        font-size: 2.75rem;
    }
    #sidebarBlueBox {
        margin-right: -50px;
    }
}
</style>
